module.exports = [{
      plugin: require('/home/vagrant/raft-website/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-55842965-1"},
    },{
      plugin: require('/home/vagrant/raft-website/node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Droid Sans","Droid Serif","Montserrat"]},"typekit":{"id":"gtr4hai"}},
    },{
      plugin: require('/home/vagrant/raft-website/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/home/vagrant/raft-website/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
