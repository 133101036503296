/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
// Import the component at the top of the file
/* import React from 'react';
import Wrapper from './src/templates/Wrapper/Wrapper';
import WrapperMobile from './src/templates/WrapperMobile/WrapperMobile';
import { isDesktop } from './src/helpers.js';

export const wrapPageElement = ({ element, props }) => {
    const desktop = isDesktop();
    console.log('browser props ',props);
    return(
        desktop 
        ? <Wrapper {...props}>{element}</Wrapper>
        : <WrapperMobile {...props}>{element}</WrapperMobile>
    )
};


 */

import React from "react"

import { GlobalState } from "./src/providers/GlobalState/GlobalState";

export const wrapRootElement = ({ element }) => {
    return (
        <GlobalState>
            {element}
        </GlobalState>
    )
}

export const onClientEntry = () => {
  // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
  if (typeof window.IntersectionObserver === `undefined`) {
    import(`intersection-observer`)
    console.log(`# IntersectionObserver is polyfilled!`)
  }
}

/*
export const shouldUpdateScroll = ({
    routerProps: { location },
    getSavedScrollPosition
  }) => {
    const currentPosition = getSavedScrollPosition(location)
    //window.scrollTo(...(currentPosition || [0, 0]))
    // Maintain scroll position if it's saved, otherwise just jumpo to the top
    console.log("currentPosition", currentPosition);
    window.scroll(...(currentPosition || [0, 0]))
    
    return false
  }
*/


/* exports.wrapPageElement = ({ element, props }) => {

    // a made up hook to detect browser size, implement your own
    const isMobile = useDetectMobile()
 
   return (
     isMobile 
       ? <MobileLayout {...props}>{element}</MobileLayout> 
       : <Layout {...props}>{element}</Layout>
 
   )
 } */